<template>
  <FimButton
    v-bind="{
      ...$props,
      ...$attrs,
      to,
      target,
      showAsIconButton,
    }"
  >
    <template v-if="$slots.badge" #badge>
      <slot name="badge" />
    </template>
    <template v-if="$slots.icon && !showAsIconButton" #icon>
      <slot name="icon" />
    </template>
    <template v-if="$slots.iconAfter && !showAsIconButton" #iconAfter>
      <slot name="iconAfter" />
    </template>

    <slot v-if="!showAsIconButton">{{ blok.cta_label }}</slot>
  </FimButton>
</template>

<script setup lang="ts">
import type { SbLink } from '../types/storyblok'
import { getLinkTarget, resolvSbLink } from '../utils/links'
import FimButton from '~/components/ui/buttons/FimButton.vue'

const props = defineProps({
  blok: {
    type: Object as PropType<SbLink>,
    required: true,
  },
})

const $currentShop = useCurrentShop()
const to = computed(() => resolvSbLink(props.blok, $currentShop))
const target = getLinkTarget(props.blok)
const showAsIconButton = computed(() => props.blok.show_as_icon_button)
</script>
